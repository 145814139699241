<template>
    <div class="use-coupons">
        <div class="title" v-if="data.status === 2" style="margin-bottom: 15px">
            您的优惠券：
        </div>
        <div class="title" v-if="data.status === 4" style="font-size: 23px">
            <p>可怜！手慢一步！</p>
             <p>卡券已经被领完啦！</p>
        </div>
        <div class="title" v-if="data.status === 3 || data.status === 0" style="font-size: 23px">
            糟糕！卡券已过期！
        </div>
        <CouponsCell :data="data" :status="data.status" @click="useCoupons"/>
        <div class="info flex" v-if="data.status === 2">
            卡券消费剩余使用时间：<van-count-down :time="time" format="DD 天 HH 时 mm 分 ss 秒"/>
        </div>

        <div class="btn" v-if="data.status === 2" @click="useCoupons">
            立即使用
        </div>

        <div class="btn btn2" v-if="data.status !== 2" @click="contactKf">
            <p>联系我的营养顾问</p>
            <p>获取最新优惠</p>
        </div>


    </div>
</template>

<script>
    import CouponsCell from "../../components/CouponsCell";
    import {Toast} from "_vant@2.12.22@vant";
    export default {
        name: "",
        components:{
            CouponsCell
        },
        data(){
            return{
                time: 24 * 60 * 60 * 1000,
                data:{}
            }
        },
        created() {
            let toast = Toast.loading({
                message: '加载中',
                forbidClick: true,
                overlay: true
            })
            let sn = this.$route.query.coupon_sn
            this.$request.get("get_coupon_info",{
                coupon_sn:sn
            }).then(res => {
                if(res.code == 1){
                    this.data = res.data

                    //获取剩下时间
                    let nowDate = new Date().getTime()
                    let endData = new Date(this.data.expire_date.replace(/-/g, '/')).getTime()

                    this.time = (endData - nowDate)
                    toast.clear()
                }else{
                    Toast(res.message)
                }
            })
        },
        methods:{
            useCoupons(){
                this.$router.push({
                    path:`/product/order/${this.data.goods_id}`
                })
            },
            contactKf(){
                this.$router.push({
                    path:'/my/consult'
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .use-coupons{
        width: 100%;
        min-height: 100vh;
        background-color: #F3F3F4;
        padding: 25px 10px;
        box-sizing: border-box;
        .title{
            font-size:16px;
           font-weight: 900;
            margin-bottom: 45px;
            line-height: 30px;
        }
        .info{
            font-size: 14px;

            color:#666666;
            line-height: 20px;
            margin-top:10px;
            .van-count-down{
                color: #FF0404;
                margin-left: 5px;

            }
        }
        .btn{
            width:80%;
            margin: 0 auto;
            text-align: center;
            padding: 10px 0px;
            color: #6B00AF;
            font-weight: 900;
            font-size: 16px;
            background-color: white;
            border-radius: 20px;
            margin-top: 45px;
        }
        .btn2{
            border-radius: 38px;
            line-height: 25px;
            padding: 7px 0px;
        }
    }

</style>